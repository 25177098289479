import React, { useState, useRef } from 'react'
import { Form, Button } from "react-bootstrap";


const WORKERS_URL = process.env.REACT_APP_WORKERS_URL

const AddPost = (props) => {

  const ref = useRef(null)

  const [fileData, setFileData] = useState('')

  const handleChange = async (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = function (event) {
      let data = event.target.result
      setFileData(data)
      //event.target.value = ''
    };
    reader.readAsDataURL(file);
  }

  const handleSubmit = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let username = document.getElementById('username').value
    let title = document.getElementById('title').value
    let content = document.getElementById('content').value

    let raw = JSON.stringify({
      username,
      title,
      content,
      media: fileData
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };
    try {
      let newPost = await fetch(`${WORKERS_URL}/posts`, requestOptions)
        .then(response => response.json())

      props.addNewPost(newPost)
      setFileData('')
      ref.current.value = ''
    } catch (err) {
      alert('Invalid response while adding. Please check the console');
      let errorMessage = err && err.message ? err.message : err;
      console.error(errorMessage);
    }
  };

  const formStyle = {
    //border: '1px dashed',
    borderRadius: '10px',
    margin: '5px auto',
    padding: '2px'
  }

  return (
    <Form style={formStyle} onSubmit={handleSubmit}>
      <Form.Group className="sm">
        { /*<Form.Label>Username</Form.Label> */}
        <Form.Control
          placeholder={props.username}
          aria-label="Username"
          aria-describedby="basic-addon1"
          id="username"
          name="username"
          //onChange={(e) => props.updateUsername(e.target.value)}
          value={props.username}
          required
          disabled
        />
      </Form.Group>

      <Form.Group className="sm">
        <Form.Control
          aria-label="Amount (to the nearest dollar)"
          placeholder="Title"
          id="title"
          name="title"
          required
        />
      </Form.Group>

      <Form.Group>
        <Form.Control
          as="textarea"
          aria-label="Write your post"
          placeholder="Write your post"
          id="content"
        />
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Upload media</Form.Label>
        <Form.Control
          type="file"
          onChange={handleChange}
          ref={ref}
        />
      </Form.Group>
      <Button style={{ textAlign: 'center', display: 'block', margin: '0 auto' }} variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default AddPost