import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";

import Posts from './components/posts'
import AddPost from "./components/addpost";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { customAlphabet } from 'nanoid/non-secure'
const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789', 10);

const WORKERS_URL = process.env.REACT_APP_WORKERS_URL

function App() {
  const [posts, setPosts] = useState([]);

  let storedUsername = sessionStorage.getItem('username')
  if (!storedUsername) {
    storedUsername = nanoid(4)
    sessionStorage.setItem('username', storedUsername)
  }

  const [username, setUsername] = useState(storedUsername)
  const [postsSortedBy, setPostsSortedBy] = useState()

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        `${WORKERS_URL}/posts` // eslint-disable-line
      );
      const postsResp = await resp.json()

      // Prep an image when you have no media
      let imgUrl = 'https://picsum.photos/200/300'
      let myRequest = new Request(imgUrl);
      let imgFallBack = await fetch(myRequest)
        .then(response => response.blob())
        .then(myBlob => {
          let objectURL = URL.createObjectURL(myBlob);
          return objectURL;
        });

      sessionStorage.setItem('imageFallbackUrl', imgFallBack)
      postsResp.forEach(p => {
        if (!p.media) {
          p.media = imgFallBack
        }
      })
      return postsResp
    };


    getPosts().then(somePosts => setPosts(somePosts))
  }, []);

  const updateUsername = newUsername => {
    sessionStorage.setItem('username', newUsername)
    setUsername(newUsername)
  }

  const addNewPost = (post) => {
    let imgFallBack = sessionStorage.getItem('imageFallbackUrl')
    if (!post.media) {
      post.media = imgFallBack
    }
    posts.unshift(post)
    setPosts(posts.map(p => p))
  }

  const updatePostWorkers = async post => {

    let postToUpdate;
    if (post.media.startsWith('blob')) {
      postToUpdate = JSON.parse(JSON.stringify(post))
      delete postToUpdate.media
    } else {
      postToUpdate = post
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(postToUpdate)
    };
    try {
      await fetch(`${WORKERS_URL}/posts`, requestOptions)// eslint-disable-line
        .then(response => response.json())
    } catch (err) {
      alert('Invalid response while adding. Please check the console');
      let errorMessage = err && err.message ? err.message : err;
      console.error(errorMessage);
    }
  }

  const updateEmoji = async (postId, username, emojiData, react = true) => {
    let post = posts.find(p => p.id === postId)
    if (react) {
      post.emoji[username] = emojiData
    } else {
      delete post.emoji[username]
    }

    await updatePostWorkers(post)
  }

  const updateVotes = async (postId, upvote = true) => {
    /*
    posts.map(p => {
      if (p.id === post.id) {
        if (upvote) {
          if (p.votes.downvotes.includes(username)) {
            p.votes.downvotes = p.votes.downvotes.filter(name => name !== username)
          }

          if (p.votes.upvotes.includes(username)) {
            p.votes.upvotes = p.votes.upvotes.filter(name => name !== username)
          } else {
            p.votes.upvotes.push(username)
          }
        } else {
          if (p.votes.upvotes.includes(username)) {
            p.votes.upvotes = p.votes.upvotes.filter(name => name !== username)
          }

          if (p.votes.downvotes.includes(username)) {
            p.votes.downvotes = p.votes.downvotes.filter(name => name !== username)
          } else {
            p.votes.downvotes.push(username)
          }
        }
      }
      return p
    })
    */

    let post = posts.find(p => p.id === postId)

    if (upvote) {
      if (post.votes.downvotes.includes(username)) {
        post.votes.downvotes = post.votes.downvotes.filter(name => name !== username)
      }

      if (post.votes.upvotes.includes(username)) {
        post.votes.upvotes = post.votes.upvotes.filter(name => name !== username)
      } else {
        post.votes.upvotes.push(username)
      }
    } else {
      if (post.votes.upvotes.includes(username)) {
        post.votes.upvotes = post.votes.upvotes.filter(name => name !== username)
      }

      if (post.votes.downvotes.includes(username)) {
        post.votes.downvotes = post.votes.downvotes.filter(name => name !== username)
      } else {
        post.votes.downvotes.push(username)
      }
    }

    setPosts(posts.map(p => p))
    /*
    if (upvote) {
      sortPosts('upvotes')
    } else {
      sortPosts('downvotes')
    }
    */

    await updatePostWorkers(post)
  }

  const sortPosts = (by) => {
    if (by === 'upvotes') {
      posts.sort((a, b) => b.votes.upvotes.length - a.votes.upvotes.length)
    } else if (by === 'downvotes') {
      posts.sort((a, b) => b.votes.downvotes.length - a.votes.downvotes.length)
    } else if (by === 'reactions') {
      posts.sort((a, b) => Object.keys(b.emoji).length - Object.keys(a.emoji).length)
    }

    setPosts(posts.map(p => p))
  }

  return (
    <div style={{
      display: 'flex',
      width: '100%',
      backgroundColor: 'wheat'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '30%',
        marginLeft: 0,
        marginRight: 'auto'
      }}>
        <h1
          style={{ margin: '0 auto' }}
        > Add Post </h1>
        <AddPost posts={posts} addNewPost={addNewPost} username={username} updateUsername={updateUsername} />
        <DropdownButton id="dropdown-basic-button"
          style={{ margin: '0 auto' }}
          title={postsSortedBy ? `Sorted by: ${postsSortedBy}` : "Sort posts"}
          onSelect={(eventKey) => {
            setPostsSortedBy(eventKey)
            sortPosts(eventKey)
          }
          }>
          <Dropdown.Item eventKey="upvotes">Upvotes</Dropdown.Item>
          <Dropdown.Item eventKey="downvotes">Downvotes</Dropdown.Item>
          <Dropdown.Item eventKey="reactions">Reactions</Dropdown.Item>
          {postsSortedBy && <Dropdown.Item eventKey="votes">Votes</Dropdown.Item>
          }
        </DropdownButton>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        //alignItems: 'flex-end'
        marginLeft: 'auto',
        marginRight: 0,
        marginTop: 0
      }}>
        {
          /*
        <HomeCarousel posts={posts} />
        */
        }
        <Router>
          <Posts path="/" posts={posts} username={username} updateVotes={updateVotes} updateEmoji={updateEmoji} />
          {/*<Post path="/" /> */}
        </Router>
      </div>
    </div>
  );
}

export default App;
