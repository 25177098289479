import React, { useState } from "react";
//import { Link } from "@reach/router";
import { Button, ButtonGroup, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Picker } from 'emoji-mart'
const FeedPost = (props) => {
  let post = props.post

  const [showPicker, setShowPicker] = useState(false)
  const [emoji, setEmoji] = useState(post.emoji[props.username])
  //const [reacted, setReacted] = useState(false)

  return (
    <Card key={post.id} style={{ width: '18rem', margin: '0 5%', backgroundColor: 'whitesmoke' }} >
      <Card.Img style={{ width: '16rem', margin: '0 auto', maxHeight: '60%', objectFit: 'cover' }} variant="top" src={post.media} />
      <Card.Body>
        <Card.Title>{post.title}</Card.Title>
        <Card.Text style={{ borderBottom: '2px solid' }} >
          {post.content}
        </Card.Text>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
          onMouseLeave={async () => {
            setShowPicker(false)
          }
          }
        >
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={`tooltip-top`}>
                {emoji ? 'Click to remove!' : 'React'}
              </Tooltip>
            }
          >
            <Button style={{ borderRadius: '50%', height: '50px', width: '50px' }}
              variant={emoji ? 'primary' : 'outline-primary'}
              onMouseEnter={async () => {
                setShowPicker(true)
              }
              }
              onClick={() => {
                if (emoji) {
                  setEmoji(null)
                  props.updateEmoji(post.id, props.username, emoji, false)
                }
              }}
            >{emoji ? emoji.native : '😀'}</Button>
          </OverlayTrigger>
          {showPicker &&
            <Picker title='Pick your emoji…' emoji='point_up'
              showPreview={false}
              showSkinTones={false}
              onSelect={(newEmoji => {
                setEmoji(newEmoji)
                props.updateEmoji(post.id, props.username, newEmoji)
              })}
              style={{
                position: 'absolute',
                bottom: '80px',
                right: '20px',
                width: '100%',
                zIndex: 2
              }} />
          }

          <ButtonGroup className="me-2" aria-label="Second group">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {post.votes.upvotes.includes(props.username) ? 'Remove upvote' : 'Add upvote'}
                </Tooltip>
              }
            >
              <Button
                variant={post.votes.upvotes.includes(props.username) ? 'secondary' : 'success'}
                onClick={() => props.updateVotes(post.id)}>👍 {post.votes.upvotes.length}</Button>

            </OverlayTrigger>

            <Button variant="primary" style={{ pointerEvents: 'none' }}>Votes</Button>

            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {post.votes.downvotes.includes(props.username) ? 'Remove downvote' : 'Downvote'}
                </Tooltip>
              }
            >

              <Button
                variant={post.votes.downvotes.includes(props.username) ? 'secondary' : 'danger'}
                onClick={() => props.updateVotes(post.id, false)}>👎 {post.votes.downvotes.length}</Button>
            </OverlayTrigger>
          </ButtonGroup>
        </div>

      </Card.Body>
    </Card >
  )
}

export default FeedPost